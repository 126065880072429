.calendar-range-preselect-container {
  padding: 3rem 1rem 0 0;

  .calendar-range-preselect-option {
    background-color: rgba(59, 130, 246, 0.2);
    padding: 0.5rem;
    color: var(--primary-500);
    border-radius: 0.25rem;

    &:hover {
      cursor: pointer;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
