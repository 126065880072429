@media (min-width: 992px) {
  .layout-wrapper {
    .layout-content-container {
      margin-left: 325px;
    }

    .layout-sidebar-main-container {
      height: calc(100vh - 17rem);
      margin-top: 2rem;
      padding-top: 3.25rem;
    }

    &.layout-sidebar-inactive {
      .layout-sidebar {
        transform: translateX(-100%);
        left: 0;
      }

      .layout-content-container {
        margin-left: 0;
        padding-left: 2rem;
      }
    }
  }
}

@media (max-width: 991px) {
  .layout-wrapper {
    .layout-content-container {
      margin-left: 0;
      padding-left: 2rem;

      .p-card-body {
        padding: 0;
      }
    }

    .layout-sidebar {
      left: 0;
      top: 0;
      height: 100vh;
      border-radius: 0;

      .p-card {
        border-radius: 0;
      }

      .layout-sidebar-main-container {
        height: calc(100vh - 7rem);
      }
    }

    &.layout-sidebar-inactive {
      .layout-sidebar {
        transform: translateX(-100%);
        left: 0;
      }
    }

    .layout-overlay.layout-overlay-active {
      display: block;
    }
  }

  .body-overflow-hidden {
    overflow: hidden;
  }
}

@media screen and (max-width: 576px) {
  .layout-topbar-logo {
    display: none !important;
  }
}
