.layout-topbar {
  position: fixed;
  height: 5rem;
  z-index: 997;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 0.5rem;
  background-color: var(--surface-card-dark);
  box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 8%);

  .layout-topbar-left {
    height: 100%;

    .layout-topbar-logo {
      height: 100%;

      svg {
        object-fit: contain;
        height: 100%;
      }
    }
  }

  button {
    color: var(--primary-color-text) !important;

    &:hover {
      background-color: rgba(white, 7%) !important;
      color: var(--primary-300) !important;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem var(--primary-color-text);
    }
  }
}
