.layout-sidebar {
  position: fixed;
  width: 325px;
  height: calc(100vh - 9rem);
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  top: 7rem;
  left: 2rem;
  transition: transform $transitionDuration, left $transitionDuration;
  border-radius: 2rem;
  background-color: var(--surface-card);
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 2%);
  overflow-y: hidden;

  .layout-sidebar-main-container {
    padding: 5.25rem 2.25rem 0 2.25rem;
    overflow-y: auto;

    .section {
      &:not(:last-child) {
        padding-bottom: 2rem;
      }

      .section-title {
        text-transform: uppercase;
        color: #a1a5b7;
        letter-spacing: 0.15rem;
        font-weight: 500;
        font-size: 0.8rem;
        padding-bottom: 1.15rem;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          padding: 0 0 1.25rem 0.75rem;

          &:hover {
            .menu-item-icon,
            a {
              color: var(--primary-500) !important;
              transition: color 0.2s ease, background-color 0.2s ease;
            }
          }

          .menu-item {
            a {
              text-decoration: none;
              color: #737892;
              font-size: 1rem;
              font-weight: 500;

              &.active {
                color: var(--primary-500) !important;
                transition: color 0.2s ease, background-color 0.2s ease;

                .menu-item-icon {
                  color: var(--primary-500) !important;
                  transition: color 0.2s ease, background-color 0.2s ease;
                }
              }
            }

            .menu-item-icon {
              margin-right: 0.65rem;
              height: 1.5rem;
              width: 1.5rem;
              color: #7e8299;
            }
          }
        }
      }
    }

    $scrollbar-space: 0.5rem;

    &::-webkit-scrollbar {
      width: calc(0.4rem + $scrollbar-space);
    }

    &::-webkit-scrollbar-thumb {
      background-clip: content-box;
      border-right: $scrollbar-space solid transparent;
      background-color: #eff2f5;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #e9edf1;
    }
  }

  .layout-sidebar-footer {
    margin: 1.75rem 1.25rem;
    background-color: rgba(59, 130, 246, 0.2);
  }
}
