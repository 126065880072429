.layout-footer {
  padding-top: 1rem;
  border-top: 1px solid var(--surface-border);

  ul {
    list-style-type: none;
    margin: 0;

    li {
      &:not(:last-child) {
        padding-right: 2rem;
      }

      a {
        text-decoration: none;
        color: var(--text-color-secondary);
        cursor: pointer;
        font-weight: 500;

        &:hover {
          color: var(--primary-500);
        }
      }
    }
  }
}
