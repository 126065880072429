html {
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--surface-ground);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
