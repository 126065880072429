.layout-overlay {
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 998;
  position: fixed;
  overflow: hidden;
  display: none;
  animation: fadein 0.3s ease-in-out 1;
}
