[data-amplify-authenticator] {
  --amplify-components-tabs-item-active-border-color: var(--primary-500);
  --amplify-components-tabs-item-focus-color: var(--primary-500);
  --amplify-components-tabs-item-active-color: var(--primary-500);
  --amplify-components-button-primary-background-color: var(--primary-500);
  --amplify-components-button-primary-hover-background-color: var(--primary-hover);
  --amplify-components-button-primary-focus-background-color: var(--primary-hover);
  --amplify-components-button-primary-active-background-color: var(--primary-hover);
  --amplify-components-tabs-item-color: var(--text-color-secondary);
  --amplify-colors-background-primary: var(--surface-card-dark);
  --amplify-components-button-link-color: var(--primary-500);
}

.amplify-button[data-variation='primary']:focus {
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #9dc1fb, 0 1px 2px 0 #000;
}
